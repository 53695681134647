import { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { STRIPE } from '@mdc/constants';

import './PricingPlans.scss';

const PricingPlans = ({ disabled, plans, currencySymbol, onPlanSelected, selectedPlan, planType }) => {
    const { t, ready } = useTranslation();

    const numberWithComma = (number) => {
        return number.toLocaleString();
    };

    const plansDOM = plans.map((plan) => {
        const isSelected = selectedPlan && selectedPlan.id === plan.id;
        const isPlanValid = planType === 'annual' && !plan.annualPrice || planType === 'monthly' && !plan.monthlyPrice ? false : true;

        const className = classNames({
            selected: isSelected,
            disabled
        }, 'planPrice');

        const handleClick = () => {
            !disabled && onPlanSelected(plan);
        };
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleClick();
            }
        };

        return (
            isPlanValid && <div
                key={plan?.id}
                className={className}
                role='button'
                onClick={handleClick}
                onKeyDown={handleKeyDown}
            >
                <span className="planLimit">{numberWithComma(plan.limit)}</span>
                <div className="float-right">
                    <span className="pricingPlanPrice">{`${currencySymbol}${planType === STRIPE.PLAN_TYPE.MONTHLY ? numberWithComma(plan.monthlyPrice) : numberWithComma(plan.annualPrice)}`}{' '}</span>
                    {planType === STRIPE.PLAN_TYPE.MONTHLY ? t('/ month') : t('/ year')}
                </div>
            </div>
        );
    });

    const formDom = useMemo(() => {
        return <Form>
            {t('Daily limit:')}
            {plansDOM}
        </Form>;
    }, [plansDOM, t]);

    if (!ready) {
        return null;
    }

    return (
        <div className='pricingPlans'>
            {formDom}
        </div>
    );
};

const PlanType = {
    id: PropTypes.string,
    limit: PropTypes.number,
    monthlyPrice: PropTypes.number,
    annualPrice: PropTypes.number
};

PricingPlans.propTypes = {
    disabled: PropTypes.bool,
    currencySymbol: PropTypes.string.isRequired,
    plans: PropTypes.arrayOf(PropTypes.shape(PlanType)).isRequired,
    selectedPlan: PropTypes.shape(PlanType),
    onPlanSelected: PropTypes.func.isRequired,
    planType: PropTypes.string
};

export default PricingPlans;
