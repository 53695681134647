import { useState } from 'react';
import { Col, Button, SplitButton, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ScoreHeader from './ScoreHeader';
import classNames from 'classnames';

import './Score.scss';

const Score = ({ category, info, detection, score, numberOfEngines, isPercentage, link, badge, scoreText, buttonText, onButtonClick, buttonDisabled, processImgSrc, isProcessing, processingText, badgeColor, scoreColor, hasButton }) => {
    const [currentDropdownItem, setCurrentDropdownItem] = useState();

    const handleDropdownClick = (option) => {
        setCurrentDropdownItem(option);
    };

    const scoreBoxClassname = classNames({
        scoreBox: hasButton,
        sandboxScore: !hasButton
    });

    const currentDropdownValue = currentDropdownItem || (hasButton && buttonText[0]);

    const generateButtonDom = () => {
        if (!hasButton) {
            return;
        }
        if (typeof buttonText === 'string') {
            return <Button
                variant='outline-primary'
                onClick={onButtonClick.bind(null, buttonText)}
                disabled={!!buttonDisabled}
            >
                {buttonText}
            </Button>;
        } else if (Array.isArray(buttonText)) {
            return <SplitButton
                title={currentDropdownValue.title}
                variant={'primary'}
                id='dropdown-split-variants-primary'
                key='Primary'
                onClick={onButtonClick.bind(null, currentDropdownValue.value)}
                disabled={!!buttonDisabled}
            >
                {
                    buttonText?.map((option) => {
                        return <Dropdown.Item
                            key={option?.value}
                            eventKey={option.value}
                            onClick={() => handleDropdownClick(option)}
                        >
                            {option.title}</Dropdown.Item>;
                    })
                }
            </SplitButton>;
        }
    };

    const buttonDom = generateButtonDom();

    return <Col md={hasButton ? 4 : 12} lg={hasButton ? 4 : 12} sm={hasButton ? 6 : 12} xs={12} className={scoreBoxClassname}>
        <ScoreHeader
            category={category}
            score ={score}
            info={info}
            detection={detection}
            numberOfEngines={numberOfEngines}
            isPercentage={isPercentage}
            badge={badge}
            badgeColor={badgeColor}
            scoreText={scoreText}
            processImgSrc={processImgSrc}
            isProcessing={isProcessing}
            processingText={processingText}
            scoreColor={scoreColor}
        />
        {hasButton && <div className='scoreFooter'>
            {!isProcessing && <>{buttonDom}</>}
            <span className='link'>
                {typeof link === 'string' ? <span dangerouslySetInnerHTML={{ __html: link }}/> : link}
            </span>
        </div>}
    </Col>;
};

Score.propTypes = {
    category: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    info: PropTypes.string.isRequired,
    detection: PropTypes.string,
    link: PropTypes.any,
    numberOfEngines: PropTypes.number,
    badge: PropTypes.string,
    scoreText: PropTypes.string,
    buttonText: PropTypes.any,
    onButtonClick: PropTypes.func,
    buttonDisabled: PropTypes.any,
    processImgSrc: PropTypes.string,
    isProcessing: PropTypes.bool,
    processingText: PropTypes.string,
    badgeColor: PropTypes.string,
    scoreColor: PropTypes.string,
    hasButton: PropTypes.bool,
    isPercentage: PropTypes.bool
};

Score.defaultProps = {
    score: 0,
    buttonText: 'Get full report',
    hasButton: true,
    isPercentage: false
};

Score.displayName = 'Score';

export default Score;
