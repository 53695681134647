import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { useTranslation } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { dayjs } from '@mdc/services';
import classnames from 'classnames';
import { ROUTES } from '@mdc/constants';

import './HistoryChart.scss';

const MARGIN = { top: 20, right: 15, bottom: 5, left: 5 };

const MARGIN_X_AXIS = { left: 15, right: 45 };

const TOOLTIP_SIZE = { height: 85, width: 215 };

const X_LABEL_SIZE = { month: 40, day: 70, hour: 90 };

const HistoryChart = ({ scanHistory, dateRange, height, width }) => {

    const { t, ready } = useTranslation();

    const xAxisRef = useRef(null);
    const yAxisRef = useRef(null);

    const mainRef = useRef(null);

    const stepBarRef = useRef(null);
    const detectedBarRef = useRef(null);
    const totalBarRef = useRef(null);

    const xAxisWidth = useMemo(() => width - MARGIN.left - MARGIN.right - MARGIN_X_AXIS.left - MARGIN_X_AXIS.right, [width]);
    const compWidth = useMemo(() => width - MARGIN.left - MARGIN.right, [width]);
    const compHeight = useMemo(() => height - MARGIN.top - MARGIN.bottom, [height]);

    const range = useMemo(() => dateRange.length === 2 ? dateRange : [new Date(), new Date()], [dateRange]);

    const x = useMemo(() => {
        return d3.scaleTime()
            .domain(range)
            .range([0, xAxisWidth]);
    }, [range, xAxisWidth]);

    const y = useMemo(() => {
        let maxAvs = scanHistory?.length > 0 ? Math.max(...scanHistory.map((d) => d.total_avs)) : 40;
        maxAvs = maxAvs >= 40 ? maxAvs : 40;
        return d3.scaleLinear()
            .domain([maxAvs, 0])
            .range([0, compHeight]);
    }, [scanHistory, compHeight]);

    const xAxis = useMemo(() => {
        if (x) {
            const duration = dayjs.duration(range[1].getTime() - range[0].getTime());
            const durationHours = duration.asHours();
            const durationDays = duration.asDays();
            const durationMonths = duration.asMonths();

            let timeFormat;
            let tickValues;

            let axis = d3.axisBottom()
                .scale(x)
                .tickSizeOuter(0);

            if (durationHours <= 24) {
                tickValues = d3.timeHour.range(range[0], range[1], durationHours / (xAxisWidth / X_LABEL_SIZE.hour) + 1);
                timeFormat = d3.timeFormat('%b %d ‘%y, %H:%M');
            } else if (durationDays < 60) {
                tickValues = d3.timeDay.range(range[0], range[1], durationDays / (xAxisWidth / X_LABEL_SIZE.day) + 1);
                timeFormat = d3.timeFormat('%b %d ‘%y');
            } else {
                tickValues = d3.timeMonth.range(range[0], range[1], durationMonths / (xAxisWidth / X_LABEL_SIZE.month) + 1);
                timeFormat = d3.timeFormat('%b ‘%y');
            }

            return axis
                .tickValues(tickValues)
                .tickFormat(timeFormat);
        }
        return undefined;
    }, [x, range, xAxisWidth]);

    const yAxis = useMemo(
        () => d3.axisRight()
            .scale(y)
            .ticks(5, 'f')
            .tickSize(compWidth - 15)
        , [compWidth]);

    useEffect(() => {
        if (xAxisRef.current && xAxis) {
            const d3xAxis = d3.select(xAxisRef.current);
            d3xAxis.call(xAxis);
            d3xAxis.selectAll('.tick').select('line').attr('y2', 9).attr('stroke', '#E9ECF2');
            d3xAxis.selectAll('.tick').select('text').attr('y', 20).attr('fill', '#B7C3DC').attr('font-size', 10);
            d3xAxis.select('.domain').remove();
        }
        if (yAxisRef.current && yAxis) {
            const d3yAxis = d3.select(yAxisRef.current);
            d3yAxis.call(yAxis);
            d3yAxis.select('.domain').remove();
            d3yAxis.selectAll('.tick').select('line').attr('stroke', '#F4F5F8');
            const textX = parseFloat(d3yAxis.select('.tick').select('text').attr('x')) + 10;
            d3yAxis.selectAll('.tick').select('text').attr('x', textX).attr('fill', '#D3D9E6').attr('font-size', 12);
        }
    }, [xAxis, yAxis, xAxisRef.current, yAxisRef.current]);

    const tooltipContent = (scan) => {
        const scanClass = classnames('detected', { 'threat': scan.total_detected_avs });

        return <>
            <strong>{t('Scan Date:')}</strong>
            <span>{dayjs(scan.start_time).format('YYYY-MM-DD, h:mm:ss')}</span>
            <br/>
            <strong>{t('Results:')}</strong>
            <span className={scanClass}>{scan.total_detected_avs}</span>
            /
            <span>{scan.total_avs}</span>
            <br/>
            <div className='detailsLink'>
                <a href={`${ROUTES.results.href}/file/${scan.data_id}/regular/overview`} rel="noopener noreferrer" target="blank">
                    {t('View Details')}
                </a>
            </div>
        </>;
    };

    const onMouseOver = useMemo(() => (d) => {
        if (mainRef.current) {
            const d3main = d3.select(mainRef.current);

            const d3tooltip = d3main.append('div')
                .classed('historyTooltip', true)
                .classed('left', x(d.date) < xAxisWidth * 2 / 3)
                .classed('right', x(d.date) >= xAxisWidth * 2 / 3)
                .html(renderToStaticMarkup(tooltipContent(d))).style('opacity', 0)
                .style('top', (MARGIN.top - TOOLTIP_SIZE.height - 10 + y(d.total_avs)) + 'px')
                .style('height', TOOLTIP_SIZE.height + 'px');

            const tooltipW = parseFloat(d3tooltip.style('width').replace('px', ''));

            const tooltipLeftPosition = x(d.date) < xAxisWidth * 2 / 3 ?
                x(d.date) + MARGIN.left + MARGIN_X_AXIS.left - 19 :
                x(d.date) + MARGIN.left + MARGIN_X_AXIS.left - tooltipW + 20;

            d3tooltip.style('left', tooltipLeftPosition + 'px');

            d3tooltip.on('mouseover', () => {
                d3tooltip.transition().style('opacity', 1);
            })
                .on('mouseout', () => {
                    d3tooltip.transition()
                        .duration(500)
                        .style('opacity', 0)
                        .transition()
                        .duration(1)
                        .remove();
                })
                .transition()
                .duration(200)
                .style('opacity', 1);
        }
    }, [x, y]);

    const onMouseOut = () => {
        if (mainRef.current) {
            const d3main = d3.select(mainRef.current);
            d3main.selectAll('.historyTooltip').transition()
                .duration(500)
                .style('opacity', 0)
                .transition()
                .duration(1)
                .remove();
        }
    };

    const drawBar = useMemo(() => (g, data, color, accessor) => g.selectAll('rect')
        .data(data)
        .join(
            (enter) => enter.append('rect')
                .attr('width', 4)
                .attr('fill', color)
                .attr('x', (d) => x(d.date))
                .attr('height', (d) => compHeight - y(d[accessor]))
                .attr('y', (d) => y(d[accessor]))
                .on('mouseover', onMouseOver)
                .on('mouseout', onMouseOut),
            (update) => update
                .attr('x', (d) => x(d.date))
                .attr('height', (d) => compHeight - y(d[accessor]))
                .attr('y', (d) => y(d[accessor]))
                .on('mouseover', onMouseOver),
            (exit) => exit.remove()
        ), [onMouseOver]);

    useEffect(() => {
        let data = [];
        let leftData = scanHistory?.length > 0 ? scanHistory[0] : undefined;

        if (scanHistory?.length > 0) {
            const timeRange = range.map((date) => date.getTime());
            scanHistory.forEach((scan) => {
                const time = scan.date.getTime();
                if (time < timeRange[0]) {
                    leftData = scan;
                } else if (time >= timeRange[0] && time <= timeRange[1]) {
                    data.push(scan);
                }
            });
        }

        if (stepBarRef.current) {
            let path = '';

            if (leftData) {
                path += `M${MARGIN_X_AXIS.left * -1},${y(leftData.total_detected_avs)}`;

                let lastDetect = leftData.total_detected_avs;

                data.forEach((d) => {
                    path += `L${x(d.date)},${y(lastDetect)}`;
                    path += `L${x(d.date)},${y(d.total_detected_avs)}`;
                    lastDetect = d.total_detected_avs;
                });


                path += `L${xAxisWidth},${y(lastDetect)}`;
                path += `L${xAxisWidth},${compHeight}`;
                path += `L${MARGIN_X_AXIS.left * -1},${compHeight}`;

                path += 'Z';
            }

            d3.select(stepBarRef.current)
                .attr('fill', 'rgba(251,33,30,0.1)')
                .attr('d', path);
        }

        if (detectedBarRef.current) {
            d3.select(detectedBarRef.current)
                .call(drawBar, data, '#CB0704', 'total_detected_avs');
        }

        if (totalBarRef.current) {
            d3.select(totalBarRef.current)
                .call(drawBar, data, '#D3D9E6', 'total_avs');
        }
    }, [range, scanHistory, x, y, compHeight, drawBar, stepBarRef.current, detectedBarRef.current, totalBarRef.current]);

    if (!ready) {
        return null;
    }

    return (
        <div className='historyChart' ref={mainRef}>
            <svg
                width={width}
                height={height + MARGIN.top + MARGIN.bottom}
            >
                <g transform={`translate(${MARGIN.left}, ${MARGIN.top})`}>
                    <g className='x-axis' transform={`translate(${MARGIN_X_AXIS.left}, ${compHeight})`} ref={xAxisRef}></g>
                    <g className='y-axis' ref={yAxisRef}></g>
                </g>
                <g transform={`translate(${MARGIN.left + MARGIN_X_AXIS.left}, ${MARGIN.top})`}>
                    <path id='linePath' ref={stepBarRef}/>
                </g>
                <g transform={`translate(${MARGIN.left + MARGIN_X_AXIS.left}, ${MARGIN.top})`} ref={totalBarRef}/>
                <g transform={`translate(${MARGIN.left + MARGIN_X_AXIS.left}, ${MARGIN.top})`} ref={detectedBarRef}/>
            </svg>
        </div>
    );
};

HistoryChart.defaultProps = {
    width: 700,
    height: 170
};

HistoryChart.propTypes = {
    scanHistory: PropTypes.array.isRequired,
    dateRange: PropTypes.array.isRequired,
    width: PropTypes.number,
    height: PropTypes.number
};

export default HistoryChart;
