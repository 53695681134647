import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { STRIPE } from '@mdc/constants';
import { StoreService } from '@mdc/services';
import Checkbox from '../common/checkbox/Checkbox';

import './Cart.scss';

const STORE_CURRENCIES = StoreService.getCurrenciesAndSymbols();

const Cart = ({
    currency,
    currencySymbol,
    onBuyCb,
    redirect,
    selectedProducts,
    user,
    planType,
    emailValidator,
    countryValidator,
    isOrganization,
    isProcessing,
    paymentCompleted,
    hasSubscriptions
}) => {

    const { t, ready } = useTranslation();

    const authenticated = !!user;

    const [purchaseDisabled, setPurchaseDisabled] = useState(false);

    const [tocAccepted, setTocAccepted] = useState(false);
    const [tpsAccepted, setTpsAccepted] = useState(false);
    const tocLabel = t('By purchasing a license you agree to our <a href=\"https://www.opswat.com/legal\" rel=\"noopener noreferrer\" target=\"_blank\">Legal Notice</a> and <a href=\"https://www.opswat.com/legal/privacy-policy\" rel=\"noopener noreferrer\" target=\"_blank\">Privacy Policy</a>'); // eslint-disable-line no-useless-escape
    const tpsLabel = t('I agree not to make the service generally available to third parties as a billable service or sublease the Services');

    const total = useMemo(() => {
        const newTotal = {
            limit: 0,
            monthlyPrice: 0,
            annualPrice: 0
        };

        selectedProducts.forEach((plan) => {
            newTotal.limit += plan.limit;
            newTotal.monthlyPrice += plan.monthlyPrice;
            newTotal.annualPrice += plan.annualPrice;
        });
        return newTotal;
    }, [selectedProducts]);

    useEffect(() => {
        if (!(total.monthlyPrice && authenticated && !hasSubscriptions && tocAccepted && tpsAccepted && emailValidator && countryValidator && !isOrganization && !isProcessing && !paymentCompleted)) {
            setPurchaseDisabled(true);
        } else {
            setPurchaseDisabled(false);
        }
    }, [total, authenticated, hasSubscriptions, tocAccepted, tpsAccepted, emailValidator, countryValidator, isOrganization, isProcessing, paymentCompleted]);

    const numberWithComma = (number) => {
        return number?.toLocaleString();
    };

    const selectedPlansDOM = selectedProducts.map((plan) => {
        return (
            <tr key={plan?.title}>
                <td>{t(plan.title)}</td>
                <td>{numberWithComma(plan.limit)}</td>
                <td>{`${currencySymbol}${planType === STRIPE.PLAN_TYPE.MONTHLY ? numberWithComma(plan.monthlyPrice) : numberWithComma(plan.annualPrice)}`}</td>
            </tr>
        );
    });

    const buyMonthly = () => {
        onBuyCb(STRIPE.PLAN_TYPE.MONTHLY);
    };

    const buyAnnual = () => {
        onBuyCb(STRIPE.PLAN_TYPE.ANNUAL);
    };

    const contactSales = () => {
        if (window) {
            window.open('https://www.opswat.com/contact', '_blank', 'noopener');
        }
    };

    const totalToPay = useMemo(() => {
        const monthly = total.monthlyPrice;
        const annualy = total.annualPrice;

        return {
            raw: planType === STRIPE.PLAN_TYPE.MONTHLY ? monthly : annualy,
            dom: `${currencySymbol}${planType === STRIPE.PLAN_TYPE.MONTHLY ? numberWithComma(monthly) : numberWithComma(annualy)}`
        };
    }, [total, planType, currencySymbol]);

    const shouldContactSales = useMemo(() => ((currencySymbol !== STORE_CURRENCIES['jpy'] && totalToPay.raw > 20000) || (currencySymbol === STORE_CURRENCIES['jpy'] && total.raw > 2520000)), [totalToPay, currencySymbol]);

    const shouldContactSalesText = useMemo(() => {
        let MAX_SUM = '20,000';
        if (currencySymbol !== STORE_CURRENCIES['jpy']) {
            return t('For transactions above {{currencySymbol}}{{amount}}, please contact sales.', { currencySymbol: currencySymbol, amount: MAX_SUM });
        }
        MAX_SUM = '2,520,000';
        return t('For transactions above {{currencySymbol}}{{amount}}, please contact sales.', { currencySymbol: currencySymbol, amount: MAX_SUM });

    }, [currencySymbol, shouldContactSales]);

    const buyPlanDom = useMemo(() => {
        if (shouldContactSales) {
            return <Button
                variant="primary"
                onClick={contactSales}
            >
                {t('Contact Sales')}
            </Button>;
        }

        const getButtonText = (planType) => {
            switch (planType) {
                case STRIPE.PLAN_TYPE.MONTHLY:
                    return t('Buy monthly');

                case STRIPE.PLAN_TYPE.ANNUAL:
                    return t('Buy annual');

                default:
                    return;
            }
        };

        return <Button
            variant="primary"
            onClick={planType === STRIPE.PLAN_TYPE.MONTHLY ? buyMonthly : buyAnnual}
            disabled={purchaseDisabled}
        >
            {isProcessing ? t('Processing') : getButtonText(planType)}
        </Button>;
    }, [isProcessing, planType, total, t, purchaseDisabled, shouldContactSales]);

    if (!ready) {
        return null;
    }

    return (
        <Col xs={12} sm={12} md={12} xl={12}>
            <div className="cart">
                <div className="cartHeader">
                    <h3>{t('Purchase Overview')}</h3>
                </div>
                <div className="cartBody">
                    <>
                        <Table>
                            <tbody>
                                {selectedPlansDOM}
                                <tr className="total">
                                    <td colSpan={2}>{t('Total')}</td>
                                    <td>{totalToPay.dom}</td>
                                </tr>

                                <tr className="info">
                                    <td colSpan={2}>
                                        {t('Prices are in')}{' '}
                                        <span className="text-uppercase">
                                            {currency}
                                        </span>
                                    </td>
                                    <td>{planType === STRIPE.PLAN_TYPE.MONTHLY ? t('per month') : t('per year')}</td>
                                </tr>
                                <tr className="buyPlan">
                                    <td colSpan={3}>
                                        {authenticated && !shouldContactSales && (
                                            <div>
                                                <Checkbox isSelected={tocAccepted} useManualCheck onChange={() => setTocAccepted(!tocAccepted)}>
                                                    <span dangerouslySetInnerHTML={{ __html: t(tocLabel) }}></span>
                                                </Checkbox>
                                                <Checkbox isSelected={tpsAccepted} useManualCheck onChange={() => setTpsAccepted(!tpsAccepted)}>
                                                    <span dangerouslySetInnerHTML={{ __html: t(tpsLabel) }}></span>
                                                </Checkbox>
                                            </div>
                                        )}
                                        {buyPlanDom}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        {shouldContactSales && (
                            <p className='text-center'>
                                {shouldContactSalesText}
                            </p>
                        )}

                        {!authenticated && (
                            <p>
                                {t('You must')}{' '}
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect(
                                            'https://id.opswat.com/register'
                                        );
                                    }}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {t('Sign Up')}
                                </a>{' '}
                                {t('or')}{' '}
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        redirect('/login');
                                    }}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {t('Login')}
                                </a>{' '}
                                {t('to complete the purchase')}
                            </p>
                        )}
                    </>
                </div>
            </div>
        </Col>
    );
};

Cart.propTypes = {
    countryValidator: PropTypes.bool,
    currency: PropTypes.string,
    currencySymbol: PropTypes.string,
    emailValidator: PropTypes.bool,
    isOrganization: PropTypes.bool,
    onBuyCb: PropTypes.func,
    planType: PropTypes.string,
    redirect: PropTypes.func.isRequired,
    selectedProducts: PropTypes.array,
    user: PropTypes.any,
    isProcessing: PropTypes.bool,
    paymentCompleted: PropTypes.bool,
    hasSubscriptions: PropTypes.bool
};

Cart.defaultProps = {
    selectedProducts: [],
    currencySymbol: '$'
};

export default Cart;
