import { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tabs, Tab, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SCAN_SANDBOX_STATUS, SANDBOX_FILE_TYPES } from '@mdc/constants';
import SandboxContext from './sandboxContext';

import './sandboxTabs.scss';

/* eslint-disable camelcase */
const SandboxTabs = ({ className, children, isNoData }) => {
    const {
        scanResults,
        sandboxResults,
        sandboxMultipleResults,
        onSelectSandboxChange,
        getLatestResults,
        fileImage
    } = useContext(SandboxContext);

    const { t, ready } = useTranslation();
    const [activeKey, setActiveKey] = useState('windows7');
    const [isNewScan, setIsNewScan] = useState(false);

    useEffect(() => {
        // Get the latest results to include `last_sandbox_id` once the scan for a fresh file is done
        if (isNewScan && (sandboxResults?.status === SCAN_SANDBOX_STATUS.done && sandboxResults?.results && !scanResults?.additional_info?.includes('sandbox'))) {
            (async () => {
                await getLatestResults();
            })();
        }

    }, [sandboxResults, isNewScan, scanResults]);

    useEffect(() => {
        const fileExtension = typeof scanResults?.file_info?.file_type_extension === 'string' ? scanResults.file_info.file_type_extension : null;
        // If the fileType is supported but we don't have `additional_info`, this is a fresh file scan
        if (SANDBOX_FILE_TYPES.some((ext) => ext.toLowerCase() === fileExtension) && !scanResults?.additional_info?.includes('sandbox')) {
            setIsNewScan(true);
        }
    }, [scanResults]);

    useEffect(() => setActiveKey((preState) => sandboxResults?.id || sandboxResults?.data?.sandbox_id || preState), [sandboxResults]);

    const definedTabs = useMemo(() =>
        [
            {
                system: 'windows7',
                title: t('Windows 7')
            },
            {
                system: 'windows10',
                title: t('Windows 10')
            }
        ], [t]);

    const tabs = useMemo(() => {
        // Don't create tabs if scan result unavailable
        if ((!scanResults || !sandboxMultipleResults) && !isNewScan) {
            return;
        }

        // Don't create tabs if there is any sandbox under scanning or error
        if (!sandboxResults?.data && sandboxResults?.status !== SCAN_SANDBOX_STATUS.loading && !isNewScan) {
            return;
        }

        // Don't create tabs if have just new sandbox scan (user should refresh page to get tabs)
        if (sandboxResults?.data && !sandboxMultipleResults[sandboxResults.data.sandbox_id]?.system && !isNewScan) {
            return;
        }

        // Create 2 defined tabs (Win7 + Win10)
        const allSandboxTabs = definedTabs.map((tab) => {
            const tabInfo = { ...tab, sandbox_id: '' };
            const multipleResults = Object.getOwnPropertyNames(sandboxMultipleResults).map((id) => sandboxMultipleResults[id]);
            if (multipleResults.some((result) => result.system === tabInfo.system)) {
                const result = multipleResults.find((outcome) => outcome.system === tabInfo.system);
                tabInfo.sandbox_id = result.sandbox_id;
                tabInfo.date = result.date;
            }

            return tabInfo;
        });

        allSandboxTabs.sort((tab1, tab2) => {
            const time1 = new Date(tab1.date || 0).getTime();
            const time2 = new Date(tab2.date || 0).getTime();

            if (time1 > time2) {
                return -1;
            } else if (time1 < time2) {
                return 1;
            }

            return 0;
        });

        return allSandboxTabs;
    }, [scanResults, sandboxResults, sandboxMultipleResults, isNewScan, t]);

    const sandboxScanTabs = useMemo(() => {
        if (tabs?.length) {
            return <Tabs activeKey={activeKey} onSelect={(key) => {
                setActiveKey(key);
                if (activeKey !== key && onSelectSandboxChange && tabs.every((tab) => tab.system !== key)) {
                    onSelectSandboxChange(key);
                }
            }}>
                {tabs.map((tab) => {
                    return <Tab key={tab?.title} title={tab.title} eventKey={tab.sandbox_id || tab.system} />;
                })}
            </Tabs>;
        }
    }, [tabs, scanResults, activeKey, sandboxResults, onSelectSandboxChange, t]);

    const tabContentDOM = useMemo(() => {
        if (scanResults && tabs?.length && tabs.some((tab) => tab.system === activeKey)) {
            const sandboxInfo = sandboxResults?.status === SCAN_SANDBOX_STATUS.error ? sandboxResults.message : '';
            return <Col className='text-center sandboxBadge'>
                <img src={fileImage.noSandboxPerformed.publicURL} alt='Perform Sandbox Scan' title='Perform Sandbox Scan' />
                <h1>{t('No dynamic analysis performed yet')}</h1>
                <p className='sandboxInfo'>{sandboxInfo}</p>
            </Col>;
        }

        if (isNoData) {
            return <Col className='text-center sandboxBadge'>
                <img src={fileImage.noSandboxPerformed.publicURL} alt='Perform Sandbox Scan' title='Perform Sandbox Scan' />
                <h1>{t('No data')}</h1>
            </Col>;
        }

        return children;
    }, [children, isNoData, activeKey, sandboxResults, scanResults, tabs]);

    if (!ready) {
        return null;
    }

    return <section className={classNames(className, 'sandboxTabs')}>
        {sandboxScanTabs}
        {tabContentDOM}
    </section>;
};

SandboxTabs.propTypes = {
    children: PropTypes.node,
    className: PropTypes.any,
    isNoData: PropTypes.bool
};

export default SandboxTabs;
