import PropTypes from 'prop-types';

import './SocialShare.scss';

const SocialShare = ({ onLink }) => {

    const onClick = (onLink) ? (event) => {
        event.preventDefault();
        onLink(event.target.href);
    } : undefined;

    return (
        <ul className="social-share">
            <li>
                <a
                    className="icon-twitter"
                    href="https://twitter.com/opswat"
                    id="icon-twitter"
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {'Twitter'}
                </a>
            </li>
            <li>
                <a
                    className="icon-facebook-official"
                    href="https://www.facebook.com/OPSWAT"
                    id="icon-facebook"
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {'Facebook'}
                </a>
            </li>
            <li>
                <a
                    className="icon-youtube-play"
                    href="https://www.youtube.com/user/opswat1"
                    id="icon-youtube"
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {'YouTube'}
                </a>
            </li>
            <li>
                <a
                    className="icon-linkedin-squared"
                    href="https://www.linkedin.com/company/opswat/"
                    id="icon-linkedin"
                    onClick={onClick}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {'LinkedIn'}
                </a>
            </li>
        </ul>
    );
};

SocialShare.propTypes = {
    onLink: PropTypes.func
};

export default SocialShare;
