import { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './IconButton.scss';

const IconButton = forwardRef(({ iconClass, href, onClick, isDisabled, className, children }, ref) => {

    const classNames = classnames({
        'icon': true,
        [iconClass]: true
    });

    const handleClick = (event) => {
        if (href) {
            return;
        }
        event.preventDefault();

        if (onClick && !isDisabled) {
            onClick();
        }
    };

    const handleKeyDown = (event) => {
        if (href) {
            return;
        }
        if (event.key === 'Enter' && onClick && !isDisabled) {
            onClick();
        }
    };

    const anchorClassnames = classnames({
        iconButton: true,
        disabled: isDisabled
    }, className);

    const attributes = href ? {
        href: href,
        rel: 'noopener noreferrer',
        target: '_blank'
    } : {};

    return (
        <a
            className={anchorClassnames}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            ref={ref}
            role='button'
            {...attributes}
        >
            <i className={classNames} />
            {children}
        </a>
    );
});

export default IconButton;

IconButton.defaultProps = {
    iconClass: '',
    href: '',
    onClick: null
};

IconButton.propTypes = {
    href: PropTypes.string,
    iconClass: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string
};
