import { localStorage } from '../local-storage/LocalStorage';
import Authenticator from '../authentication/Authenticator';
import { ADVANCED_OPTIONS_KEY, PAID_ONLY_HEADERS, ADVANCED_OPTIONS_DEFAULTS, MULTISCAN_LAST_ADVANCED_OPTIONS_KEY, SANDBOX_LAST_ADVANCED_OPTIONS_KEY } from '@mdc/constants';
import ADVANCED_OPTIONS from '@mdc/ui/src/scan-input/advanced-options/Options';

const SANDBOX_DEFAULT_VALUES = ['sandbox'];
const DEPENDENT_MULTISCAN_VALUES = ['filepassword', 'unarchive'];

class AdvancedOptions {
    constructor() {
        this.headers = {};
        this.isPaidUser = false;
        this.filepassword = '';
        this.selectedValues = this.getSelectedOptions();
        this.ADVANCED_OPTIONS = ADVANCED_OPTIONS;

        Authenticator.events.on(Authenticator.events.topics.STATE_CHANGE, this.onAuthStateChange);
    }

    onAuthStateChange = (data) => {
        // eslint-disable-next-line camelcase
        this.isPaidUser = data?.paid_user === 1;
        this.selectedValues = this.getSelectedOptions();
    }

    _getHeaders = () => {
        const advancedOptionsValue = localStorage.getItem(ADVANCED_OPTIONS_KEY, true);
        const isFreeUserHeaders = this.isPaidUser ? [Object.keys(ADVANCED_OPTIONS_DEFAULTS)[0]] : Object.keys(ADVANCED_OPTIONS_DEFAULTS);
        this.headers = Array.isArray(advancedOptionsValue) ? advancedOptionsValue : isFreeUserHeaders;
        // get rule params values
        const ruleHeaders = this.ADVANCED_OPTIONS.filter((option) => option.param === 'rule' && !option.disabled).reduce((options, option) => {
            return options.concat(option.value);
        }, []).filter((header) => this.headers.includes(header)).join(',');

        // get sandbox params values
        const sandboxHeaders = this.ADVANCED_OPTIONS.filter((option) => option.param === 'sandbox' && this.headers.includes(option.param)).reduce((options, option) => {
            if (option.param === 'sandbox') {
                options = { sandbox: 'windows10' };
            } else {
                options[option.param] = {};
            }

            return options;
        }, {}) || {};
        // get other values
        const individualParams = this.ADVANCED_OPTIONS.filter((option) => option.param !== 'rule' && option.param !== 'sandbox' && this.headers.includes(option.param)).reduce((options, option) => {
            if (option.param === 'filepassword') {
                options['filepassword'] = encodeURIComponent(this.filepassword);
            } else {
                options[option.param] = option.value;
            }

            return options;
        }, {}) || {};


        this.headers = {
            ...sandboxHeaders,
            'rule': ruleHeaders,
            ...individualParams
        };
        return this.headers;
    }

    _getFilteredHeaders = () => {
        if (this.isPaidUser) {
            return this._getHeaders();
        }
        const headers = this.getSelectedOptions();
        const validKeys = headers.filter((header) => {
            return !PAID_ONLY_HEADERS.includes(header);
        });
        let fitleredHeaderObj = {};

        validKeys.forEach((key) => {
            fitleredHeaderObj[key] = headers[key];
        });

        return fitleredHeaderObj;
    }

    hasHeader = (headerName) => {
        // eslint-disable-next-line no-prototype-builtins
        return this._getFilteredHeaders().hasOwnProperty(headerName);
    }

    getHeaders = () => {
        const headers = { ...this._getHeaders() };

        !this.isPaidUser && PAID_ONLY_HEADERS.forEach((header) => {
            delete headers[header];
        });

        return headers;
    }

    updatefilepassword = (value) => {
        this.filepassword = value;
        return this.filepassword;
    }

    getSelectedOptions = () => {
        const advancedOptionsValue = localStorage.getItem(ADVANCED_OPTIONS_KEY, true);
        const isFreeUserHeaders = this.isPaidUser ? [Object.keys(ADVANCED_OPTIONS_DEFAULTS)[0]] : Object.keys(ADVANCED_OPTIONS_DEFAULTS);

        return Array.isArray(advancedOptionsValue) ? advancedOptionsValue : isFreeUserHeaders;
    }

    parseSandboxOptions = (lastSandboxOptions, sandboxParamValues, checkForValue) => {
        // add sandbox if any `sandbox_` option is selected
        if (!this.selectedValues.includes('sandbox')) {
            this.selectedValues.push('sandbox');
            if (lastSandboxOptions) {
                // if we select `sandbox`, add back the last options
                lastSandboxOptions.map((option) => option && this.selectedValues.push(option));
            } else {
                // if no last options, add the defaults
                SANDBOX_DEFAULT_VALUES.forEach((option) => option && this.selectedValues.push(option));
            }
            localStorage.removeItem(SANDBOX_LAST_ADVANCED_OPTIONS_KEY, true);
        } else {
            this.selectedValues = this.selectedValues.filter((selectedValue) => !sandboxParamValues.includes(selectedValue));
            this.selectedValues.push(checkForValue);
        }
    }

    _handleSelectSandboxOptions = (param, lastSandboxOptions, sandboxParamValues, checkForValue) => {
        if (param.indexOf('sandbox_') === 0) {
            this.parseSandboxOptions(lastSandboxOptions, sandboxParamValues, checkForValue);
        } else {
            this.selectedValues.push(param);
            // if we select `sandbox`, add back the last options
            if (lastSandboxOptions && !this.selectedValues.some((selectedValue) => lastSandboxOptions.includes(selectedValue))) {
                lastSandboxOptions.map((option) => option && this.selectedValues.push(option));
            } else if (!this.selectedValues.some((selectedValue) => SANDBOX_DEFAULT_VALUES.includes(selectedValue))) {
                // if no last options, add the defaults
                SANDBOX_DEFAULT_VALUES.forEach((option) => option && this.selectedValues.push(option));
            }
            localStorage.removeItem(SANDBOX_LAST_ADVANCED_OPTIONS_KEY, true);
        }
    }


    _handleSelectOption = (param, value, lastSandboxOptions, checkForValue, sandboxParamValues, lastMultiscanOptions) => {
        if (param.indexOf('sandbox') === 0) {
            this._handleSelectSandboxOptions(param, lastSandboxOptions, sandboxParamValues, checkForValue);
            return;
        }

        if (param === 'filepassword') {
            this.selectedValues.push(checkForValue);
            !this.selectedValues.includes('unarchive') && this.selectedValues.push('unarchive');
            // if selecting `filepassword`, we must disable hash-lookup` and select `unarchive`
            this.selectedValues = this.selectedValues.filter((selectedValue) => selectedValue !== 'hash-lookup');

        } else if (value === 'cdr' || param === 'hash-lookup') {
            this.selectedValues.push(checkForValue);

        } else {
            // if we have `lastMultiscanOptions`, add them back
            this.selectedValues.push(checkForValue);
            if (lastMultiscanOptions && !this.selectedValues.some((selectedValue) => lastMultiscanOptions.includes(selectedValue))) {
                lastMultiscanOptions.map((option) => option && this.selectedValues.push(option));

            }
        }
    }

    _handleDeselectOption = (param, checkForValue, lastMultiscanOptions) => {
        // remove selected value
        if (param.indexOf('sandbox_') === 0) {
            // if it's a `sandbox_` value, we must keep one option for each group (`sandbox_os`, `sandbox_timeout`, `sandbox_browser`)
            if (!this.selectedValues.includes(checkForValue)) {
                this.selectedValues = this.selectedValues.filter((selectedValue) => selectedValue !== checkForValue);
            }
        } else {
            this.selectedValues = this.selectedValues.filter((selectedValue) => selectedValue !== checkForValue);

            // remove `filepassword` if `unarchive` is not selected
            if (this.selectedValues.includes('filepassword') && !this.selectedValues.includes('unarchive')) {
                this.selectedValues = this.selectedValues.filter((selectedValue) => selectedValue !== 'filepassword');
            }
        }

        // if deselecting group dependent options
        let dependentValuesIncluded = [];
        if (checkForValue === 'multiscan') {
            // `filepassword` and `unarchive` can't be selected if `multiscan` is not selected
            dependentValuesIncluded = this.selectedValues.filter((selectedValue) => DEPENDENT_MULTISCAN_VALUES.includes(selectedValue));
            if (dependentValuesIncluded) {
                dependentValuesIncluded.forEach((dependentValue) => lastMultiscanOptions.push(dependentValue));

                localStorage.setItem(
                    MULTISCAN_LAST_ADVANCED_OPTIONS_KEY,
                    lastMultiscanOptions,
                    true
                );
            }
            this.selectedValues = this.selectedValues.filter((selectedValue) => !DEPENDENT_MULTISCAN_VALUES.includes(selectedValue));

        } else if (param === 'sandbox') {
            // removing `sandbox` will trigger removing the previous or the default `sandbox` options

            this.selectedValues = this.selectedValues.filter((selectedValue) => selectedValue !== 'sandbox');
        }
    }

    _getValue = (param, value) => {
        if (param === 'rule') {
            return value;
        }

        return param.indexOf('sandbox_') === 0 ? `${param}-${value}` : param;
    };

    updateSelectedOptions = (param, value, isPassword) => {
        if (isPassword) {
            this.ADVANCED_OPTIONS = this.ADVANCED_OPTIONS.map((option) => {
                if ((option.param === 'hash-lookup' || option.param === 'filepassword' || option.param === 'privateprocessing') && option.param === param) {
                    option.value = value;
                }

                return option;
            });
            this.getHeaders();
            return;
        }

        const checkForValue = this._getValue(param, value);
        const sandboxAdvancedOptions = param.indexOf('sandbox_') === 0 ? this.ADVANCED_OPTIONS.filter((option) => option.param === 'sandbox')[0].value.filter((option) => option.param === param)[0].values : [];
        const sandboxParamValues = sandboxAdvancedOptions ? sandboxAdvancedOptions?.reduce((result, option) => {
            return result.concat(`${param}-${option.key}`);
        }, []) : [];

        const lastMultiscanOptions = localStorage.getItem(MULTISCAN_LAST_ADVANCED_OPTIONS_KEY, true) || [];
        let lastSandboxOptions = localStorage.getItem(SANDBOX_LAST_ADVANCED_OPTIONS_KEY, true) || null;

        if (this.selectedValues.includes(checkForValue)) {
            this._handleDeselectOption(param, checkForValue, lastMultiscanOptions);
        } else {
            this._handleSelectOption(param, value, lastSandboxOptions, checkForValue, sandboxParamValues, lastMultiscanOptions);
        }

        localStorage.setItem(ADVANCED_OPTIONS_KEY, this.selectedValues, true);
    }

    processRuleHeaders(rules) {
        // Remove 'sanitize' if any, Because user may still has last local storage from last FE version
        if (rules.includes('sanitize')) {
            rules = rules.split(',').filter((rule) => rule !== 'sanitize').join(',');
        }

        if (rules.includes('multiscan') && rules.includes('cdr')) {
            // If both 'multiscan' and 'cdr' present => merge them into 'sanitize'
            rules = rules.split(',').filter((rule) => rule !== 'multiscan' && rule !== 'cdr').concat(['sanitize']).join(',');
        } else if (rules.includes('cdr') && !rules.includes('multiscan')) {
            // If 'cdr' presents, no 'multiscan' => remove all other rules, only keep 'cdr'
            rules = rules.split(',').filter((rule) => rule === 'cdr').join(',');
        }
        return rules;
    }
}

export default new AdvancedOptions();
