import { useState, memo, useEffect, useMemo, useContext } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { StoreService, LocationService, logService, dayjs } from '@mdc/services';
import { NotificationContext } from '@mdc/ui';
import { NOTIFICATION_TYPES } from '@mdc/constants';

import '../common/icon-button/IconButton.scss';
import './ActiveLicenses.scss';

const useLocalCurrency = () => {
    const [currency, setCurrency] = useState('usd');
    useEffect(() => {
        (async () => {
            if ((process.env.ENVIRONMENT !== 'local')) {
                const response = await LocationService.getLocation();
                const moneyType = StoreService.getCurrency(response.countryName);
                setCurrency(moneyType);
            }
        })();
    }, []);
    return currency;
};

const ActiveLicenses = ({ license, paidApikeyExpirationDate }) => {

    const { t, ready } = useTranslation();
    const { notify } = useContext(NotificationContext);
    const subscriptionIsCanceled = useMemo(() => {
        return license && license.canceled_at;
    }, [license]);

    const expirationDate = useMemo(() => {
        return paidApikeyExpirationDate;
    }, [paidApikeyExpirationDate]);

    const localCurrency = useLocalCurrency();
    const currency = useMemo(() => localCurrency, [localCurrency]);

    const parseStoreLicense = (licenseInformation) => {
        const subscriptionPlans = licenseInformation.purchase_information || [];
        const subs = subscriptionPlans.map(
            (plan) => plan && plan.nickname
        );

        return {
            lastReceiptURL: licenseInformation.receipt_url,
            subs,
            subscriptionId: licenseInformation.subscription_id,
            subscriptionExpired: dayjs(licenseInformation.period_end || 0).isAfter(),
        };
    };

    const {
        lastReceiptURL,
        subs: subscriptions,
        subscriptionExpired,
    } = useMemo(() => {
        return license ? parseStoreLicense(license) : { subs: ['Custom'] };
    }, [license]);


    const subscriptionsDom = subscriptions.map((subscription) => {
        return (
            <div key={subscription}>
                {subscription}
            </div>
        );
    });

    const renewalLabel = subscriptionExpired && !subscriptionIsCanceled ? t('Renewal Date') : t('Expiration Date');

    const manageSubscription = async () => {
        try {
            await StoreService.createBillingPortalSession(currency);
        } catch (error) {
            logService.error(error);
            const errorDetails = error.message && t(error.message);
            notify({
                message: `${t('Failed to create Portal Session!')} ${errorDetails}`,
                type: NOTIFICATION_TYPES.CRITICAL
            });
        }
    };

    const manageSubscriptionDom = useMemo(() => {
        if (!subscriptions) {
            return;
        }

        const lgSpan = subscriptionExpired ? { span: 8, offset: 4 } : { span: 12 };
        const buttonClassName = subscriptionExpired ? 'float-sm-right' : 'float-none';

        return <Col className="manageSubscription" lg={lgSpan} md={12} sm={12}>
            <Button variant="outline-primary" className={`manageSubscriptionButton ${buttonClassName}`} onClick={manageSubscription}>
                {t('Manage subscription')}
            </Button>

        </Col>;
    }, [subscriptions, subscriptionExpired, subscriptionIsCanceled, manageSubscription]);

    const manageSubscriptionsDom = useMemo(() => {
        if (!(expirationDate && dayjs(expirationDate).isAfter())) {
            return <>
                <Col lg={6} md={4} sm={12}>
                    <Row>
                        {manageSubscriptionDom}
                    </Row>
                </Col>
            </>;
        }
        return <>
            <Col lg={6} md={8} sm={12}>
                <Row>
                    <Col lg={5} md={4} sm={4}> {t(renewalLabel)} </Col>
                    <Col lg={7} md={8} sm={8} className="valuesText valueCol"> {dayjs(expirationDate).format('DD MMMM YYYY')} </Col>
                </Row>
                <Row>
                    <Col lg={5} md={4} sm={4}>
                        {t('Active Licenses')}
                    </Col>
                    <Col lg={7} md={8} sm={8} className="valueCol valuesText">
                        {subscriptionsDom}
                    </Col>
                </Row>
            </Col>
            <Col lg={6} md={4} sm={12}>
                <Row>
                    {manageSubscriptionDom}
                </Row>
            </Col>
            <Col className="downloadLinks">
                {lastReceiptURL &&
                    <div><a href={lastReceiptURL} rel="noopener noreferrer" target="_blank">{t('Download latest receipt')}</a></div>
                }
            </Col>
        </>;
    }, [renewalLabel, expirationDate, subscriptionsDom, manageSubscriptionDom, t, lastReceiptURL]);

    const activeLicenseDom = useMemo(() => {
        return <Col>
            {t('Active License')}
            {dayjs(expirationDate).isBefore() &&
                <span className="text-danger"> ({t('No active license').toLowerCase()})</span>
            }
        </Col>;
    }, [expirationDate]);

    if (!ready) {
        return null;
    }

    return (
        <div className="activeLicenses">
            <Row className="description">
                {activeLicenseDom}
            </Row>
            <Row>
                {manageSubscriptionsDom}
            </Row>
        </div>
    );
};

ActiveLicenses.propTypes = {
    license: PropTypes.any.isRequired,
    paidApikeyExpirationDate: PropTypes.string,
};

export default memo(ActiveLicenses);
