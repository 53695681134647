import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ExpandableCell = ({ entryName, entryFunctions }) => {
    const [expandSymbol, setExpandSymbol] = useState('+');
    const [isExpanded, setIsExpanded] = useState(false);
    const [expandableData, setExpandableData] = useState();

    const headlineClassName = classNames({
        expanded: isExpanded
    }, 'expandableCell');

    const handleClick = (functions) => {
        setIsExpanded(!isExpanded);
        isExpanded ? setExpandSymbol('+') : setExpandSymbol('-');
        isExpanded ? setExpandableData() : setExpandableData(functions);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick(entryFunctions);
        }
    };

    return <tr className='expandableRow'>
        <td className='lighterText noMarginTop'></td>
        <td className='expandableColumn'>
            <span className={headlineClassName} role='button' onClick={() => handleClick(entryFunctions)} onKeyDown={handleKeyDown} >
                {`[${expandSymbol}] ${entryName}`}
            </span>
            <div className='expandableData'>{expandableData && expandableData.map((data) => {
                let keyCounter = 0;
                return <span key={keyCounter++}>{data}</span>;
            })}</div>
        </td>
    </tr>;
};

ExpandableCell.propTypes = {
    entryName: PropTypes.string.isRequired,
    entryFunctions: PropTypes.array.isRequired
};

export default ExpandableCell;
