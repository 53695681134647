import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../icon-button/IconButton';
import Clipboard from 'react-clipboard.js';
import classNames from 'classnames';

import './CopyButton.scss';

const CopyButton = ({ data, icon, copyText = null, copiedText = null }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleClick = () => {
        setIsCopied(true);
        window.setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    };

    const copyTextClasses = classNames({
        'disabled': isCopied
    }, 'copyText');

    const spanDom = <span
        className={copyTextClasses}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role='button'
    >
        {isCopied ? `(${copiedText})` : `(${copyText})`}
    </span>;

    return <Clipboard component="span" data-clipboard-text={data} className="copyToClipboard">
        <IconButton iconClass={`icon-${isCopied ? 'ok' : icon}`} onClick={handleClick} isDisabled={isCopied} />
        {copyText ? spanDom : null}
    </Clipboard>;
};

CopyButton.propTypes = {
    data: PropTypes.any.isRequired,
    icon: PropTypes.string.isRequired,
    copyText: PropTypes.bool,
    copiedText: PropTypes.string
};

export default CopyButton;
