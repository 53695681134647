import classnames from 'classnames';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import PropTypes from 'prop-types';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Notification.scss';

const AVAILABLE_THEMES = { LIGHT: 'light', DARK: 'dark' };

const Notification = ({ type, message, alwaysVisible, theme, handleClick }) => {
    const notificationTypeClass = classnames({
        info: type === NOTIFICATION_TYPES.INFO,
        warning: type === NOTIFICATION_TYPES.WARNING,
        critical: type === NOTIFICATION_TYPES.CRITICAL,
        success: type === NOTIFICATION_TYPES.SUCCESS
    }, 'notification');

    const notificationThemeClass = classnames({
        light: theme === AVAILABLE_THEMES.LIGHT,
        dark: theme === AVAILABLE_THEMES.DARK,
        info: type === NOTIFICATION_TYPES.INFO,
        warning: type === NOTIFICATION_TYPES.WARNING,
        critical: type === NOTIFICATION_TYPES.CRITICAL,
        success: type === NOTIFICATION_TYPES.SUCCESS
    }, 'toastNotificationContent');

    const getIcon = () => {
        if (type === NOTIFICATION_TYPES.WARNING || type === NOTIFICATION_TYPES.CRITICAL) {
            return (<i className="notificationIcon icon-warning-empty" />);
        } else if (type === NOTIFICATION_TYPES.SUCCESS) {
            return (<i className="notificationIcon icon-award-solid" />);
        } else if (type === NOTIFICATION_TYPES.INFO) {
            return (<i className="notificationIcon icon-info-circled" />);
        }

        return null;
    };

    // eslint-disable-next-line react/prop-types
    const CloseButton = ({ closeToast }) => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                closeToast();
            }
        };

        return (
            <i
                className="icon icon-close"
                role='button'
                onClick={() => closeToast()}
                onKeyDown={handleKeyDown}
            />
        );
    };

    if (!message) {
        return null;
    }

    const expirationTime = alwaysVisible ? false : Math.min(Math.max(message.length * 50, 5000), 7000);

    const ToastMessage = () => (
        <div className='toastMessage'>
            {getIcon()}
            <span className='notificationMessage' dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    );

    const toastComponent = toast(<ToastMessage />, {
        autoClose: expirationTime,
        className: notificationThemeClass,
        onClose: handleClick || ''
    });

    return (
        <div className={notificationTypeClass}>
            {toastComponent}
            <ToastContainer
                position="top-right"
                className='toastNotificationWrapper'
                closeOnClick={false}
                pauseOnFocusLoss
                transition={Slide}
                pauseOnHover
                autoClose={false}
                closeButton={CloseButton}
            />
        </div>
    );
};

export default Notification;

Notification.defaultProps = {
    alwaysVisible: false
};

Notification.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
    alwaysVisible: PropTypes.bool,
    theme: PropTypes.string,
    handleClick: PropTypes.func
};
