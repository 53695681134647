import Card from './card/Card';
import Checkbox from './common/checkbox/Checkbox';
import CookieBanner from './cookie-banner/CookieBanner';
import useCookieBannerState from './cookie-banner/useCookieBannerState';
import DropZoneProvider, {
    DropZoneConsumer,
    DropZoneContext
} from './drop-zone/DropZone';
import FileInput from './common/file-input/FileInput';
import Footer from './footer/Footer';
import Header from './header/Header';
import IconButton from './common/icon-button/IconButton';
import MainMenu from './main-menu/MainMenu';
import ScanInput from './scan-input/ScanInput';
import TextInput from './common/text-input/TextInput';
import TextCarousel from './text-carousel/TextCarousel';
import UploadPreloader from './upload-preloader/UploadPreloader';
import NotificationBarProvider, {
    NotificationBarConsumer,
    NotificationContext
} from './notification-bar/NotificationBar';
import LoadingSpinnerProvider, {
    LoadingSpinnerConsumer,
    LoadingSpinnerSharedContext
} from './loading-spinner/LoadingSpinner';
import Spinner from './loading-spinner/loading/Loading';
import UserInfoBar from './user-info-bar/UserInfoBar';
import ApikeyInfo from './apikeyInfo/ApikeyInfo';
import ActiveLicenses from './activeLicenses/ActiveLicenses';
import Table from './common/table/Table';
import TimeFromNow from './common/table/cell-components/time-from-now/TimeFromNow';
import FileStatus from './common/table/cell-components/file-status/FileStatus';
import SubmissionStatus from './common/table/cell-components/submission-status/SubmissionStatus';
import CveAffectedVersion from './common/table/cell-components/cve-affected-version/CveAffectedVersion';
import CveResolution from './common/table/cell-components/cve-resolution/CveResolution';
import Cart from './store/Cart';
import PricingPlans from './store/PricingPlans';
import Product from './store/Product';
import Store from './store/Store';
import Leaderboard from './top-leaderboards/Leaderboard';
import ChartBar from './common/table/cell-components/ChartBar';
import Licensing from './licensing/Licensing';
import Faq from './common/faq/Faq';
import FaqV2 from './common/faq-v2/FaqV2';
import HubspotModal from './common/hubspot-modal/HubspotModal';
import Component404 from './404/Component404';
import UserContext, { UserProvider } from './user-context/UserContext';
import UserInfoPlaceholder from './common/content-placeholder/UserInfoPlaceholder';
import GraphicsPlaceholder from './common/content-placeholder/GraphicsPlaceholder';
import TablePlaceholder from './common/content-placeholder/TablePlaceholder';
import ButtonsPlaceholder from './common/content-placeholder/ButtonsPlaceholder';
import TextPlaceholder from './common/content-placeholder/TextPlaceholder';
import AnnouncementBanner from './announcement-banner/AnnouncementBanner';
import useAnnouncementBannerState from './announcement-banner/useAnnouncementBannerState';
import ConfirmModal from './common/confirm-modal/ConfirmModal';
import CveStatus from './cve/CveStatus';
import VoteButtons from './common/vote-buttons/VoteButtons';
import VoteButton from './common/vote-buttons/VoteButton';
import FileOverview from './results/FileOverview';
import Score from './results/score/Score';
import ScoreHeader from './results/score/ScoreHeader';
import SandboxScore from './results/sandbox/SandboxScore';
import ResultsHeader from './results/header/ResultsHeader';
import Alert from './results/alert/Alert';
import FileBasedVulnerability from './results/file-based-vulnerability/FileBasedVulnerability';
import CodeSnippet from './results/code-snippet/CodeSnippet';
import DataSanitization from './results/data-sanitization/DataSanitization';
import HistoryGraph from './results/common/history-graph/HistoryGraph';
import CommunityGraph from './results/common/community-graph/CommunityGraph';
import PlainTable from './common/plain-table/PlainTable';
import HorizontalTable from './common/horizontal-table/HorizontalTable';
import LightButton from './results/common/light-button/LightButton';
import ScorePlaceholder from './results/common/score-placeholder/ScorePlaceholder';
import PlainTablePlaceholder from './results/common/plain-table-placeholder/PlainTablePlaceholder';
import DownloadSanitizedButton from './results/common/download-sanitized-button/DownloadsanitizedButton';
import VulnerabilityReportModal from './results/vulnerability-report-modal/VulnerabilityReportModal';
import ScrollTopButton from './common/scroll-button/ScrollTopButton';
import UnderConstruction from './common/under-construction/UnderConstruction';
import StatusNotification from './common/status-notification/StatusNotification';
import CopyButton from './common/copy-button/CopyButton';
import ErrorBoundary from './error-boundary/ErrorBoundary';
import Pagination from './common/plain-table/Pagination';
import NetPromoterScore from './net-promoter-score/NetPromoterScore';
import DataPreviewTooltip from './common/data-preview-tooltip/DataPreviewTooltip';
import NetPromoterScoreManager from './net-promoter-score/NetPromoterScoreManager';
import SandboxTabs from './results/sandbox/sandboxTabs';
import SandboxContext from './results/sandbox/sandboxContext';
import ThankYou from './store/ThankYou';
import IpRestriction from './ip-restriction/IpRestriction';
import InternalLink from './common/internal-link/InternalLink';
import CustomButtonGroup from './account/button-group/ButtonGroup';
import Filter from './account/filter/Filter';
import StatisticsCard from './account/reports/StatisticsCard';
import StatisticsFileType from './account/reports/statistics/file-type/FileType';
import ApexChart from './common/apex-chart/ApexChart';
import PieChart from './common/apex-chart/PieChart';
import FeedbackPopup from './common/popup/FeedbackPopup';
import StackedBar from './common/apex-chart/stackedBar';
import NotificationPopup from './notifications/NotificationPopup';
import InAppNotificationContext, { InAppNotificationProvider } from './notifications/InAppNotificationContext';
import AccountBanner from './account/reports/banner/AccountBanner';
import ProcessesDiagram from './process-diagram/ProcessesDiagram';
import ExpandableTable from './common/table/ExpandableTable';
import TextModal from './common/text-modal/TextModal';
import PDFExport from './multiscan/multiscanPDF';

import '@mdc/styles/node_modules/bootstrap/scss/bootstrap.scss';

export const store = {
    Cart,
    PricingPlans,
    Product,
    Store,
    ThankYou
};

export const results = {
    Score,
    ScoreHeader,
    ResultsHeader,
    Alert,
    FileBasedVulnerability,
    CodeSnippet,
    DataSanitization,
    FileOverview,
    ScorePlaceholder,
    HistoryGraph,
    CommunityGraph,
    PlainTablePlaceholder,
    DownloadSanitizedButton,
    VulnerabilityReportModal,
    SandboxScore,
    SandboxTabs,
    SandboxContext,
};

export const account = {
    CustomButtonGroup,
    Filter,
    StatisticsFileType,
    AccountBanner
};

export const placeholders = {
    GraphicsPlaceholder,
    UserInfoPlaceholder,
    TablePlaceholder,
    TextPlaceholder,
    ButtonsPlaceholder
};

export {
    Card,
    Checkbox,
    CookieBanner,
    useCookieBannerState,
    DropZoneProvider,
    DropZoneConsumer,
    DropZoneContext,
    FileInput,
    Footer,
    Header,
    IconButton,
    MainMenu,
    ScanInput,
    TextInput,
    TextCarousel,
    UploadPreloader,
    NotificationBarProvider,
    NotificationBarConsumer,
    LoadingSpinnerProvider,
    LoadingSpinnerConsumer,
    LoadingSpinnerSharedContext,
    Spinner,
    NotificationContext,
    UserInfoBar,
    ApikeyInfo,
    ActiveLicenses,
    Table,
    Leaderboard,
    ChartBar,
    TimeFromNow,
    FileStatus,
    SubmissionStatus,
    CveAffectedVersion,
    CveResolution,
    Licensing,
    Faq,
    FaqV2,
    Component404,
    HubspotModal,
    UserContext,
    UserProvider,
    AnnouncementBanner,
    useAnnouncementBannerState,
    ConfirmModal,
    CveStatus,
    VoteButtons,
    VoteButton,
    PlainTable,
    HorizontalTable,
    LightButton,
    ScrollTopButton,
    UnderConstruction,
    StatusNotification,
    CopyButton,
    ErrorBoundary,
    Pagination,
    NetPromoterScore,
    DataPreviewTooltip,
    NetPromoterScoreManager,
    IpRestriction,
    InternalLink,
    StatisticsCard,
    ApexChart,
    PieChart,
    StackedBar,
    FeedbackPopup,
    NotificationPopup,
    InAppNotificationContext,
    InAppNotificationProvider,
    ProcessesDiagram,
    ExpandableTable,
    TextModal,
    PDFExport
};
