import i18n from './i18n';
import { COOKIE_POLICY_STATUS, COOKIE_POLICY_STATUS_DURATION, COOKIE_POLICY_ACCEPTED, COOKIE_USER_LAST_ACTIVITY, COOKIE_OPSWAT_REDIRECT, COOKIE_ANOUNCEMENT_BANNER, COOKIE_ANOUNCEMENT_BANNER_CLOSE_DURATION, COOKIE_ALREADY_USED } from './cookie';
import { ADVANCED_OPTIONS_DEFAULTS, ADVANCED_OPTIONS_KEY, MULTISCAN_LAST_ADVANCED_OPTIONS_KEY, SANDBOX_LAST_ADVANCED_OPTIONS_KEY, PAID_ONLY_HEADERS, API_KEY, CDN_PATH, STATUS_PAGE, HUBSPOT_FORMS, HUBSPOT_PORTAL_ID } from './misc';
import { BASE_URL, BASE_FQDN_URL, API } from './backend';
import { METADEFENDER } from './metadefender';
import DLP_FILE_TYPES from './dpl-file-types';
import SANDBOX_FILE_TYPES from './sandbox-file-types';
import SANITIZATION_FILE_TYPES from './sanitization-file-types';
import WORKFLOWS from './workflows';
import SCAN_STATES from './scan-states';
import ERRORS from './errors';
import ROUTES from './routes';
import LOOKUP_TYPES from './lookup-types';
import NOTIFICATION_TYPES from './notification-types';
import UPLOAD_PRELOADER_STATES from './upload-preloader-states';
import ENGINE_STATUS from './engine-status';
import { OutbreakShareURL, MarketShareURL } from './reports';
import * as STRIPE from './stripe';
import OS_NAME from './os-name';
import SCAN_FILE_STATUS from './scan-file-status';
import SCAN_SANITIZED_STATUS from './scan-sanitized-status';
import SCAN_SANDBOX_STATUS from './scan-sandbox-status';
import SCAN_INTERVAL from './scan-interval';
import SCAN_ADDRESS_STATUS from './scan-address-status';
import RESULT_TEXTS from './result-texts';

const { STRIPE_PUBLIC_KEY } = STRIPE;

export {
    i18n as i18nConfig,
    ADVANCED_OPTIONS_DEFAULTS,
    ADVANCED_OPTIONS_KEY,
    MULTISCAN_LAST_ADVANCED_OPTIONS_KEY,
    SANDBOX_LAST_ADVANCED_OPTIONS_KEY,
    API,
    API_KEY,
    BASE_FQDN_URL,
    BASE_URL,
    COOKIE_OPSWAT_REDIRECT,
    COOKIE_POLICY_ACCEPTED,
    COOKIE_POLICY_STATUS,
    COOKIE_POLICY_STATUS_DURATION,
    COOKIE_ANOUNCEMENT_BANNER as COOKIE_ANOUNCEMENT_BANNER_SHOWN,
    COOKIE_ANOUNCEMENT_BANNER_CLOSE_DURATION,
    COOKIE_ALREADY_USED,
    COOKIE_USER_LAST_ACTIVITY,
    CDN_PATH,
    DLP_FILE_TYPES,
    ERRORS,
    LOOKUP_TYPES,
    METADEFENDER,
    NOTIFICATION_TYPES,
    ENGINE_STATUS,
    ROUTES,
    SANDBOX_FILE_TYPES,
    SANITIZATION_FILE_TYPES,
    SCAN_STATES,
    STRIPE,
    STRIPE_PUBLIC_KEY,
    UPLOAD_PRELOADER_STATES,
    WORKFLOWS,
    HUBSPOT_PORTAL_ID,
    HUBSPOT_FORMS,
    OutbreakShareURL,
    MarketShareURL,
    PAID_ONLY_HEADERS,
    OS_NAME,
    SCAN_FILE_STATUS,
    SCAN_SANITIZED_STATUS,
    SCAN_SANDBOX_STATUS,
    SCAN_INTERVAL,
    SCAN_ADDRESS_STATUS,
    STATUS_PAGE,
    RESULT_TEXTS,
};
