import { ENGINE_STATUS } from '@mdc/constants';
import { useMemo } from 'react';
import { dayjs } from '@mdc/services';

export const PDF_HEADER_VALUES = (t) => [[
  { content: t('Engine'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
  { content: t('Result'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
  { content: t('Last Update'), styles: { textColor: '#3D4A68', fontStyle: 'bold' } },
]];

export const mapResults = (scanResult) => {
  switch (scanResult) {
    case ENGINE_STATUS.INFECTED:
      return 'Infected';
    case ENGINE_STATUS.SUSPICIOUS:
      return 'Suspicious';
    case ENGINE_STATUS.NO_THREAT:
      return 'No threat detected';
    case ENGINE_STATUS.ABORTED:
      return 'Aborted';
    case ENGINE_STATUS.UNSUPPORTED_FILE_TYPE:
      return 'Unsupported file type';
    default:
      return 'No threat detected';
  }
};

export const usePdfData = (data) => {
  return useMemo(() => {
    if (!data?.scan_details) {
      return;
    }
    const headRows = Object.keys(data.scan_details);

    const bodyRows = Object.entries(data.scan_details).map(([key, value]) => {
      return {
        id: key,
        'Result': mapResults(value.scan_result_i),
        'Last Update': !value || new Date(value.def_time).getTime() === 0 ? '-' : dayjs(value.def_time).format('ll')
      };
    });
    return { headRows, bodyRows };
  }, [data]);
};

export const multiscanPDFData = (pdfData) => {
  const body = pdfData?.headRows?.map((header) => {
    const headRowEntriesLength = pdfData.bodyRows.filter((bodyRowTemp) => bodyRowTemp.id === header).length;
    const pdfBodyRows = pdfData.bodyRows.reduce((acc, item) => {
      if (item.id === header) {
        const { ...itemProcessed } = item;
        acc.push(Object.values(itemProcessed));
      }
      return acc;
    }, []);

    if (pdfBodyRows.length === headRowEntriesLength) {
      const unshiftObject = {
        rowSpan: headRowEntriesLength,
        content: header,
        styles: {
          valign: 'middle',
        }
      };

      if (headRowEntriesLength !== 0) {
        pdfBodyRows[0].unshift(unshiftObject);
      } else {
        pdfBodyRows.unshift([unshiftObject]);
      }
    }

    return pdfBodyRows;
  }).flat(1);

  return body;
};

