/* eslint-disable camelcase */
import metadefenderService from '../metadefender/MetadefenderService';
import { logService } from '@mdc/services';

const POLLING_ATTEMPTS_NO = 5;

const ADDITIONAL_DATA_MAPS = [
    { info: 'exif', key: 'exif' },
    { info: 'peinfo', key: 'peInfo' },
    { info: 'apk-manifest', key: 'apkInfo' },
];

/**
 * Lookup for additional data using metadefender service
 *
 * @param {object} results
 * @param {object} lastAdditionDataResults
 *
 * @returns {array} exif, peinfo information
 */
const getAdditionalData = async (results, lastAdditionDataResults) => {
    const additionalInfo = results.additional_info;
    const hash = results?.file_info.sha1 || results?.file_info.sha256 || results?.file_info.md5;

    const additionalData = lastAdditionDataResults && typeof lastAdditionDataResults === 'object' ? lastAdditionDataResults : {};
    additionalData.getSuccess = true;

    for (let map of ADDITIONAL_DATA_MAPS) {
        if (!(additionalInfo?.includes(map.info) && !additionalData[map.key])) {
            continue;
        }

        try {
            const data = await metadefenderService.hash.getAdditional({ hash, info: map.info })[0];
            if (data?.data?.status === 'processing') {
                additionalData.getSuccess = false;
                continue;
            }
            additionalData[map.key] = data;
        } catch (err) {
            if (err?.response?.status === 404) {
                // Get Api data fail
                additionalData.getSuccess = false;
                continue;
            }
            logService.log(err);
        }
    }

    return additionalData;
};

/**
 * Lookup for results using metadefender service
 *
 * @param {string} action
 * @param {string} id
 *
 * @returns {object} results information
 */
const getResults = async (action, id) => {
    let results;

    if (action === 'hash') {
        results = await metadefenderService.hash.getResults({ hash: id })[0];
    } else {
        results = await metadefenderService.file.getResults({ dataId: id })[0];
    }

    return { results };
};

class FileResults {
    constructor() {
        this.fetchingDataId = undefined;

        this.results = undefined;
        this.peInfo = undefined;
        this.exif = undefined;
        this.apkInfo = undefined;
        this.historyHash = undefined;
        this.scanHistory = undefined;
        this.esKey = undefined;

        this.loadedComponents = [];

        this.afterSanitizedResults = undefined;

        this.fetchingSandboxId = undefined;
    }

    // Lock fetching data id, to prevent fetching multiple results when user upload multiple file continually
    // Only get result from latest upload file
    setFetchingDataId = (id) => this.fetchingDataId = id;

    verifyDataId = (id, pollingAttemptsCount) => {
        if (this.fetchingDataId && id !== this.fetchingDataId && pollingAttemptsCount === 1) {
            return { results: undefined, peInfo: undefined, exif: undefined };
        }
    };

    lookupResults = async (action, id, lastAdditionDataResults, pollingAttemptsCount = 1) => {
        this.verifyDataId(id, pollingAttemptsCount);

        let results = this.results || {};

        if (pollingAttemptsCount === 1) {
            results = await getResults(action, id);
            results = results.results.data;
        }

        let additionData;

        // If result contain addition info and shouldn't be 'In Progress' or 'In Queue' state
        if ((results?.additional_info?.some((info) => ADDITIONAL_DATA_MAPS.some((map) => map.info === info)))
            &&
            (results?.scan_results?.scan_all_result_i !== 255 && results?.scan_results?.scan_all_result_i !== 254)) {
            additionData = await getAdditionalData(results, lastAdditionDataResults);
            if (!additionData.getSuccess) {
                setTimeout(() => {
                    this.getResultsData(action, id, lastAdditionDataResults, pollingAttemptsCount + 1);
                }, 5000);
            }
        }

        let { exif, peInfo, apkInfo } = additionData || {};

        const exifData = exif?.data?.vs_version_info || exif?.data || {};
        const peInfoData = peInfo?.data?.vs_version_info || peInfo?.data || {};
        const apkInfoData = apkInfo?.data || apkInfo || {};
        const { esKey } = this;

        exif = exif?.data || {};
        peInfo = peInfo?.data || {};
        apkInfo = apkInfo?.data || {};

        results.file_info = results.file_info || {};

        const fileInfoMerged = { ...results?.file_info, ...exifData, ...peInfoData, ...apkInfoData };
        results.file_info = fileInfoMerged;

        this.setResultsData(results, peInfo, exif, apkInfo);

        return { results, peInfo, exif, apkInfo, esKey };
    };

    getResultsData = async (action = null, id = null, lastAdditionDataResults = null, pollingAttemptsCount = 1) => {
        if (!this.results && action && id && pollingAttemptsCount === 1) {
            this.setFetchingDataId(id);
            const { results, exif, peInfo, apkInfo } = await this.lookupResults(action, id, lastAdditionDataResults, pollingAttemptsCount);
            const { esKey } = this;
            return { results, exif, peInfo, apkInfo, esKey };
        }

        if (pollingAttemptsCount !== 1 && pollingAttemptsCount <= POLLING_ATTEMPTS_NO) {
            const { results, peInfo, exif, apkInfo } = await this.lookupResults(action, id, lastAdditionDataResults, pollingAttemptsCount);
            this.setResultsData(results, peInfo, exif, apkInfo);
            return { results, peInfo, exif, apkInfo };
        }

        let { results, peInfo, exif, apkInfo, esKey } = this;

        results = results?.data || results;
        peInfo = peInfo?.data || peInfo;
        exif = exif?.data || exif;
        apkInfo = apkInfo?.data || apkInfo;

        return { results, peInfo, exif, apkInfo, esKey };
    };

    setResultsData = (results, peInfo, exif, apkInfo) => {
        this.results = results?.data || results;
        this.peInfo = peInfo?.data || peInfo;
        this.exif = exif?.data || exif;
        this.apkInfo = apkInfo?.data || apkInfo;
    };

    getScanHistory = async (hash) => {
        if (hash && (hash !== this.historyHash)) {
            let history;
            try {
                history = await metadefenderService.hash.getScanHistory({ hash })[0];
            } catch (err) {
                return err?.response?.status === 404 ? false : undefined;
            }
            if (history?.data?.scan_result_history) {
                this.scanHistory = history.data.scan_result_history;
                this.historyHash = hash;
                return this.scanHistory;
            }
        } else if (this.historyHash && hash === this.historyHash) {
            return this.scanHistory;
        }
    };

    clearScanHistory = () => {
        this.scanHistory = undefined;
        this.historyHash = undefined;
    };

    getLoadedComponent = async (hash) => {
        const exists = this.loadedComponents.filter((component) => component.hash === hash);

        if (exists.length) {
            return exists[0].result;
        } else if (hash) {
            let loadedComponentsResults;
            try {
                loadedComponentsResults = await metadefenderService.hash.getResults({ hash })[0];
            } catch (e) {
                /* eslint-disable-next-line no-console */
                logService.log(e);
                return undefined;
            }

            const result = {
                display_name: loadedComponentsResults?.data?.file_info?.display_name,
                total_detected_avs: loadedComponentsResults?.data?.scan_results?.total_detected_avs,
                total_avs: loadedComponentsResults?.data?.scan_results?.total_avs,
            };

            this.loadedComponents.push({ result, hash });
            return result;
        }
    };

    getAfterSanitizedResults = async (dataId) => {
        if (this.afterSanitizedResults?.data_id === dataId) {
            return this.afterSanitizedResults;
        }

        let results;
        try {
            results = await metadefenderService.file.getResults({ dataId })[0];
        } catch (e) {
            /* eslint-disable-next-line no-console */
            logService.log(e);
            return undefined;
        }

        if (results?.data?.scan_results) {
            this.afterSanitizedResults = results.data;
            return this.afterSanitizedResults;
        }
    };

    cleanAfterSanitizedResults = () => {
        this.afterSanitizedResults = undefined;
    };

    // Lock fetching sandbox id, to prevent continually fetching sandbox result, when user already navigate to other file results page
    setFetchingSandboxId = (id) => this.fetchingSandboxId = id;
}

export default new FileResults();
