import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import DataTable from './data-table/DataTable';
import PaginationNavigation, { NAVIGATION_DIRECTIONS } from './pagination-navigation/PaginationNavigation';
import { TableDataManager, UPDATE_REASON } from '@mdc/services';
import './Table.scss';

const Table = ({
    rowsPerPage,
    footerInfoBox,
    columns,
    data,
    useSorting,
    useInfiniteScroll,
    getNewDataHandler,
    isInitialized,
    onPageChange,
    onPageChangeRequest,
    restrictTable,
    initialPage
}) => {
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [allPages, setAllPages] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [isCompInitialized, setIsCompInitialized] = useState(isInitialized);
    const [tableDataManager, setTableDataManager] = useState(() => {
        if (data) {
            return new TableDataManager({
                rowData: rowData,
                rowsPerPage: +rowsPerPage,
                getNewDataHandler,
                isInfiniteScrolling: useInfiniteScroll,
                initialPage: currentPage
            });
        }

        return null;
    });

    if (!useInfiniteScroll && rowData !== data) {
        setRowData(data);
        setTableDataManager(new TableDataManager({
            rowData: [],
            rowsPerPage: +rowsPerPage,
            getNewDataHandler,
            initialPage: currentPage
        }));
    }

    useEffect(() => {
        if (allPages && initialPage && initialPage > allPages) {
            setCurrentPage(allPages);
            tableDataManager.setPage(allPages);
        } else {
            setCurrentPage(initialPage);
            tableDataManager.setPage(initialPage);
        }
    }, [initialPage, restrictTable]);

    const onPageNavigation = ({ direction, totalPages }) => {
        let requestedPage;
        switch (direction) {
            case NAVIGATION_DIRECTIONS.FIRST_PAGE:
                requestedPage = 1;
                break;
            case NAVIGATION_DIRECTIONS.PREVIOUS_PAGE:
                requestedPage = currentPage - 1;
                break;
            case NAVIGATION_DIRECTIONS.NEXT_PAGE:
                requestedPage = currentPage + 1;
                break;
            case NAVIGATION_DIRECTIONS.LAST_PAGE:
                requestedPage = totalPages;
                break;
            default:
        }

        if (restrictTable && requestedPage > restrictTable) {
            return onPageChangeRequest(requestedPage);
        }

        tableDataManager.setPage(requestedPage);
    };

    const onPageUpdate = ({ reason }) => {
        let actualPage ;
        switch (reason) {
            case UPDATE_REASON.FILTER:
                setAllPages(tableDataManager.getTotalPages());
            // eslint-disable-next-line no-fallthrough
            case UPDATE_REASON.PAGE_CHANGE:
                actualPage = tableDataManager.getCurrentPage();
                setCurrentPage(actualPage);
                onPageChange(actualPage);
                break;
            case UPDATE_REASON.NEW_DATA:
                setAllPages(tableDataManager.getTotalPages());
                break;
        }

    };

    const onStateUpdate = ({ reason }) => {
        switch (reason) {
            case UPDATE_REASON.REQUEST_NEW_DATA:
                break;
            case UPDATE_REASON.NO_MORE_DATA:
                break;
        }
    };

    useEffect(() => {
        tableDataManager.on(tableDataManager.topics.DATA_UPDATE, onPageUpdate);
        tableDataManager.on(tableDataManager.topics.STATE_UPDATE, onStateUpdate);


        return function clean() {
            tableDataManager.off(tableDataManager.topics.DATA_UPDATE, onPageUpdate);
            tableDataManager.off(tableDataManager.topics.STATE_UPDATE, onStateUpdate);
        };
    }, [tableDataManager]);

    useEffect(() => {
        tableDataManager.updateData(rowData);
    }, [rowData, tableDataManager]);

    useEffect(() => {
        setIsCompInitialized(isInitialized);
    }, [isInitialized]);

    const footerInfoBoxContainer = useMemo(() => {
        return footerInfoBox ? <div className="footerInfoBox">{footerInfoBox}</div> : null;
    }, [footerInfoBox]);

    const paginationContainer = useMemo(() => {
        return rowsPerPage ? (
            <PaginationNavigation
                onNavigation={onPageNavigation}
                currentPage={currentPage}
                totalPages={allPages}
                restrictTable={restrictTable}/>
        ) : null;
    }, [rowsPerPage, onPageNavigation, currentPage, allPages, tableDataManager]);

    const tableFooter = useMemo(() => {
        if (!footerInfoBoxContainer && !paginationContainer) {
            return null;
        }

        return (
            <div className="tableFooter">
                {footerInfoBoxContainer}
                {paginationContainer}
            </div>
        );
    }, [footerInfoBoxContainer, paginationContainer, tableDataManager]);


    const DataTableComponent = useMemo(() => {
        return (<DataTable
            columns={columns}
            rowDataManager={tableDataManager}
            useSorting={useSorting}
            useInfiniteScroll={useInfiniteScroll}/>);
    }, [tableDataManager, columns, useSorting, useInfiniteScroll]);

    const hasData = rowData.length > 0;

    return (
        <div className="tableWrapper">
            {DataTableComponent}
            {isCompInitialized && hasData && tableFooter}
        </div>
    );
};

Table.defaultProps = {
    useSorting: true,
    rowsPerPage: 0,
    isInitialized: true,
    onPageChange: () => { return; },
    initialPage: 1
};

Table.propTypes = {
    onPageChangeRequest: PropTypes.func,
    rowsPerPage: PropTypes.number,
    footerInfoBox: PropTypes.element,
    columns: PropTypes.array.isRequired,
    data: (props, propName) => {
        const data = props[propName];
        const isInitialized = props['isInitialized'];

        if (isInitialized && !Array.isArray(data)) {
            return new Error(`Proptype ${propName} should be an array when 'isInitialized' is true`);
        }
    },
    useSorting: PropTypes.bool,
    isInitialized: PropTypes.bool,
    getNewDataHandler: PropTypes.func, //must return a promise with data
    useInfiniteScroll: (props, propName, componentName) => {
        const useInfiniteScroll = props[propName];

        if (useInfiniteScroll && typeof props.getNewDataHandler !== 'function') {
            return new Error(`Please provide a callback on 'getNewDataHandler' for ${componentName}, if you are using 'useInfiniteScroll'.`);
        }
    },
    onPageChange: PropTypes.func,
    restrictTable: PropTypes.number,
    initialPage: PropTypes.number,
};

export default Table;
