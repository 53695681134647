import BaseService from '../BaseService';

class SandboxService {
    constructor(instance) {
        this.instance = instance;
        this.base = new BaseService(this.instance);
    }

    getLookup = (params, headers) => {
        return this.base.get(`/sandbox/${params.id}`, headers);
    }

    postScan = (params, data, headers) => {
        return this.base.post(`/sandbox/${params.dataId}/scan`, data, headers);
    }
}

export default SandboxService;
