import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { i18nUtility } from '@mdc/services';
import classnames from 'classnames';

import './DragAndDropBar.scss';

const DND_BAR_ACTIVE = 'dndBarActive';

const DragAndDropBar = () => {
    const [targetElem, setTargetElem] = useState(null);
    const { t, ready } = useTranslation();

    const currentLang = i18nUtility.lookupLang();

    const textClassnames = classnames({
        jpText: currentLang === 'ja-JP'
    }, 'text');

    useEffect(() => {
        if (!targetElem) {
            setTargetElem(document.getElementsByTagName('body')[0]);
            return;
        }

        const toggleClass = () => {
            if (targetElem.classList) {
                targetElem.classList.toggle(DND_BAR_ACTIVE);
            } else {
                const classes = targetElem.className.split(' ');
                const classIndex = classes.indexOf(DND_BAR_ACTIVE);

                if (classIndex > -1) {
                    classes.splice(classIndex, 1);
                } else {
                    classes.push(DND_BAR_ACTIVE);
                }
                targetElem.className = classes.join(' ');
            }
        };

        toggleClass();

        return () => toggleClass();
    }, [targetElem]);

    if (!ready) {
        return null;
    }

    const DragAndDropBarHTML = (<div className='dndBar'>
        <i className="icon-drop" />
        <span className={textClassnames}>
            {t('DROP FILE')}
        </span>
    </div>);

    return targetElem ? createPortal((
        DragAndDropBarHTML
    ), targetElem) : DragAndDropBarHTML;
};

export default DragAndDropBar;
