import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import classNames from 'classnames';

import './ScoreHeader.scss';

const DETECTION_TYPES = ['detected', 'notDetected', 'notPerformed', 'suspicious', 'benign', 'unknown', 'informational', 'likelyMalicious', 'malicious'];

const ScoreHeader = ({ category, categoryNumber, score, scoreColor, info, detection, isPercentage, numberOfEngines, badge, scoreText, processImgSrc, isProcessing, processingText, badgeColor }) => {
    const detectionType = detection ? DETECTION_TYPES[DETECTION_TYPES.indexOf(detection)] : '';

    const infoTextClasses = classNames({
        noThreatsDetected: score === 0 || typeof score !== 'number',
    }, 'infoText', detectionType);

    const primaryNumberClasses = classNames({
        zeroLead: score < 10 && score !== 0,
        threatsDetected: score !== 0,
        noNumber: typeof score !== 'number' || score < 0,
        onlyZero: score === 0,
        redColor: typeof numberOfEngines === 'number' && (scoreColor !== 'success' || scoreColor !== 'warning'),
        benign: scoreColor === 'benign',
        unknown: scoreColor === 'unknown',
        informational: scoreColor === 'informational',
        suspicious: scoreColor === 'suspicious',
        likelyMalicious: scoreColor === 'likelyMalicious',
        malicious: scoreColor === 'malicious',
    }, 'primaryNumber');

    const badgeVariant = !score || typeof score !== 'number' || score < 0 ? 'light' : 'danger';

    const primaryNumberDom = <p className={primaryNumberClasses}>
        {score > 0 ? score : ''}
    </p>;

    const badgeDom = useMemo(() => <Badge variant={badgeColor ? badgeColor : badgeVariant}>{badge}</Badge>, [badgeColor, badgeVariant, badge]);

    const enginesNumber = typeof numberOfEngines === 'number' ? `/${numberOfEngines}` : 'Detections';
    const scoreDom = <div className='score'>
        {primaryNumberDom}
        <p className={'secondaryNumber'}>
            {isPercentage ? '%' : enginesNumber}
            <span>
                {badge ? badgeDom : scoreText}
            </span>
        </p>
    </div>;

    const processDOM = useMemo(() => {
        return <div className='processBox'>
            <p className='infoText'>{processingText}</p>
            <div>
                <img src={processImgSrc} alt='Process Img' title='Process image' />
            </div>
        </div>;
    }, [processImgSrc, processingText]);

    return (
        <div className='scoreHeader'>
            <div className='topInfo'>
                <span className='category'>
                    {categoryNumber && <span className="categoryNumber">{categoryNumber}</span>}
                    {category}
                </span>

                {!isProcessing && <p className={infoTextClasses}>
                    {info}
                </p>
                }
            </div>
            {!isProcessing && scoreDom}
            {isProcessing && processDOM}
        </div>
    );
};

ScoreHeader.propTypes = {
    category: PropTypes.string.isRequired,
    categoryNumber: PropTypes.any,
    score: PropTypes.number.isRequired,
    scoreColor: PropTypes.string,
    info: PropTypes.string,
    detection: PropTypes.string,
    numberOfEngines: PropTypes.number,
    badge: PropTypes.string,
    scoreText: PropTypes.string,
    processImgSrc: PropTypes.string,
    isProcessing: PropTypes.bool,
    processingText: PropTypes.string,
    badgeColor: PropTypes.string,
    isPercentage: PropTypes.bool
};

ScoreHeader.defaultProps = {
    score: 0,
    isPercentage: false
};

export default ScoreHeader;
