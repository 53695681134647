import { useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../icon-button/IconButton';
import Popup from '../../popup/Popup';
import classnames from 'classnames';

import './FilterPopup.scss';

const FilterPopup = ({ filterData, onFilter }) => {
    const [active, setActive] = useState([]);
    const popuptTriggerRef = useRef();

    const onFilterClick = (value) => {
        let newActiveFilters;
        if (!active.includes(value)) {
            newActiveFilters = [...active, value];
        } else {
            const currentValueIndex = active.indexOf(value);
            active.splice(currentValueIndex, 1);
            newActiveFilters = [...active];
        }

        onFilter(newActiveFilters);
        setActive(newActiveFilters);
    };

    const filterItems = useMemo(() => {
        return filterData.map(({ value, label, icon }) => {
            const isActive = active.includes(value);
            const filterItemClassnames = classnames({
                isActive
            }, 'filterItem');
            const handleKeyDown = (event) => {
                if (event.key === 'Enter') {
                    onFilterClick(value);
                }
            };

            const iconComponent = icon ? (icon) : null;
            return (
                <div key={value} className={filterItemClassnames} role='button' onClick={() => onFilterClick(value)} onKeyDown={handleKeyDown}>
                    {iconComponent}<span>{label}</span>
                </div>
            );
        });
    }, [filterData, active]);

    return (
        <div className="filterWrapper">
            <IconButton iconClass="icon-filter" ref={popuptTriggerRef} className="filterTrigger" />
            <Popup triggerRef={popuptTriggerRef} className="filterPopupContainer">
                {filterItems}
            </Popup>
        </div>
    );
};

FilterPopup.propTypes = {
    filterData: PropTypes.array.isRequired,
    onFilter: PropTypes.func.isRequired
};

export default FilterPopup;
